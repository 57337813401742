<template>
    <v-container fluid>
        <v-tabs v-model="tab" >
            <v-tab href="#tab-airportList"> {{$t('airport_list')}}</v-tab>
            <v-tab href="#tab-domesticMovement">{{$t('domestic_movements')}}</v-tab>
            <v-tab-item
                    value="tab-airportList"
            >
                <AirportListReportTable v-if="tab==='tab-airportList'"></AirportListReportTable>
            </v-tab-item>
            <v-tab-item
                    value="tab-domesticMovement"
            >
                <AirportDomesticMovementTable v-if="tab==='tab-domesticMovement'"></AirportDomesticMovementTable>
            </v-tab-item>
        </v-tabs>
    </v-container>
</template>

<script>
    export default {
        name: "HotelReportTable",
        props: [
            'moduleName'
        ],
        components: {
            AirportListReportTable: () => import(/* webpackChunkName: "AirportListReportTable" */ './AirportListReportTable'),
            AirportDomesticMovementTable: () => import(/* webpackChunkName: "AirportDomesticMovementTable" */ './AirportDomesticMovementTable'),
        },
        computed: {
            reportIsMonthWise() {
                return this.serverParams.ReportType === "monthwise";
            }
        },
        data() {
            return {
                tab: 'tab-airportList',
            };
        },
        methods:{},
        mounted() {

        }
    }
</script>

<style scoped lang="scss">

</style>